import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../assets/images/AdBlueLogo.jpg';
import PropTypes from 'prop-types';

const Logo = ({ type }) => {
    const compLogo = useSelector((state) => state.user);
    const [imga, setImga] = useState(null);
    useEffect(() => {
        if(compLogo.distributor.logoFilePath) {
            axios.get(process.env.REACT_APP_API_URL + '/files/userlogo/'/*  + compLogo.distributor.logoFilePath.replace(/\\/g, "%5C") */, { headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken') }, responseType: 'blob' })
            .then(res => {
                setImga(res.data)
            })
        }
    }, [compLogo])

    switch (type) {
        case 'mainLogo':
            return (
                <img src={logo} alt='AdBlueLogo' style={{ 'width': '210px', 'height': 'calc: (100% + 50px)', 'overflow': 'visible' }} />
            )
        case 'companyLogo':
            return (
                imga !== null && <img src={URL.createObjectURL(imga)} alt='CompanyLogo' style={{ 'width': '100%', 'height': '100%', 'overflow': 'visible' }}/>
            )
        default:
            return
    }
};

Logo.propTypes = {
    type: PropTypes.string
}

export default Logo;
